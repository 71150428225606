/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { ScanTable } from "../components/scan-table";
import { Button, Column, Row, FlexRow } from "../components/defaults";
import Checkbox from "../components/checkbox";
import api from "../utils/api";
import useAuth from "../utils/useAuth";
import { NotificationManager } from "react-notifications";
import {  downloadCSV } from '../utils/jsonToCsv'

import "react-datepicker/dist/react-datepicker.css";

const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';

const Contents = styled.div`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'Satoshi-Medium'
`;

const ToggleWrapper = styled(Row)`
    background: rgba(5, 31, 115, 0.04);
    border-radius: 100px;
    height: 40px;
    padding: 5px;
    gap: 5px;
`;

const ToggleItem = styled(Column)`
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    box-sizing: border-box;

    ${props => props.selected ? `
        background: white;
        color: rgba(79, 89, 240, 1);
        pointer-events: none;
    `: `
        color: rgba(91, 99, 125, 1);
        cursor: pointer;
    `}
`;

const Toggle = ({
    options,
    selected,
    setSelected,
}) => {

    return <ToggleWrapper>
        {options.map((o) => <ToggleItem 
        onClick={() => setSelected(o)}
        selected={selected===o}>{o}</ToggleItem>)}
    </ToggleWrapper>
}

const Input = styled.input`
    font-size: 16px;
    font-family: 'Satoshi-Regular';
    background: rgba(249, 249, 249, 1);
    border-radius: 5px;
    border: 1px solid rgba(219, 219, 219, 1);
    padding: 10px 10px;
    height: 43px;
    outline: none;
    box-sizing: border-box;
    width: 150px;
`;


const LoadFrom = ({ loading, reload }) => {

    const [value, setValue] = useState('');

    const onInputKeyDown = (e) => {
        if (value && e.key === 'Enter') reload(value)
    }

    return <Row style={{ alignSelf: 'flex-end', flex: 1 }}>
        <Input onKeyDown={onInputKeyDown} value={value} onChange={(e) => setValue(e.target.value)} style={{ marginRight: 10, width: 250 }} placeholder="Load from lab. number" />
        <Button onClick={() => reload(value)} loading={loading} disabled={!value} style={{ borderRadius: 5}}>Load</Button>
    </Row>

}

const DownloadScanReport = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [reportLoading, setReportLoading] = useState(false)
    const [onlyRegisteredAfterScanned, setOnlyRegisteredAfterScanned] = useState(false)

    const downloadScanReport = async () => {
        setReportLoading(true)
        try {
            const startDateFormatted = startDate.toISOString().substring(0, 10)
            const endDateFormatted = endDate.toISOString().substring(0, 10)
            let reportName = 'scanned-kits'
            let queryParams = `start_date=${startDateFormatted}&end_date=${endDateFormatted}`
            if (onlyRegisteredAfterScanned) {
                queryParams += `&only_registered_after_scanned=1`
                reportName = 'registered-kits-after-scanned'
            }
            const result = await api.get(`/admin/scan-report?${queryParams}`);
            if (!result.data.length) {
                NotificationManager.info(`There is no data to generate report!`);
                return
            }
            downloadCSV(result.data, reportName)
            NotificationManager.success(`Report generated with success!`);
        } catch (err) {
            NotificationManager.error(`Error to generate report!`);
        } finally {
            setReportLoading(false)
        }
    }
    return (
      <FlexRow style={{ gap: 10, alignItems: 'flex-end'  }}>
        <Row style={{ gap: 10 }}>
            <Column style={{ gap : 7 }}>
                <div style={{ fontSize: 13 }}>Start Date</div>
                <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                customInput={<Input />}
                dateFormat="yyyy-MM-dd"
                />
            </Column>
            <Column style={{ gap : 7 }}>
            <div style={{ fontSize: 13 }}>End Date</div>
            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                customInput={<Input />}
                dateFormat="yyyy-MM-dd"
            />
            </Column>
        </Row>
        <Column>
        <Checkbox
            labelStyle={{ fontSize: 13, color: 'rgba(0,0,0,.6)' }}
            label="Only kits registered after scanned"
            selected={onlyRegisteredAfterScanned}
            onClick={() => setOnlyRegisteredAfterScanned((previous) => !previous)}
        />
        <Button
            onClick={downloadScanReport}
            loading={reportLoading} disabled={reportLoading || (!startDate || !endDate)}
            style={{ borderRadius: 5, width: 'auto', marginTop: 10 }}
        >   Download Scan Report
        </Button>
        </Column>
      </FlexRow>
    );
}

export const ScanPage = () => {

    const toggleOptions = [
        'Type Entry',
        'Scan Entry'
    ]

    const [scanOption, setScanOption] = useState(toggleOptions[0]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const { claims } = useAuth()


    const loadRowsFromNumber = async (number) => {
        setLoading(true)
        try {
            const result = await api.get(`/admin/scan?number=${number}`);
            const lab_number_result = await api.get('/admin/lab-number');
            const newRows = []
            for (let data of result.data) {                
                newRows.push({
                    lab_number: data.laboratory_number,
                    additional_info: data.additional_info || '',
                    barcode: data.barcode,
                    status: data.status,
                    email: data?.user_data?.email,
                    name: data?.user_data?.name,
                    date_of_birth: data?.user_data?.dob ? new Date(data?.user_data?.dob).toLocaleDateString() : '',
                    chronological_age: data?.user_data?.chronological_age,
                    last_biological_age: data?.user_data?.last_biological_age,
                    scanned_at: data?.user_data?.scanned_at ? new Date(data?.user_data?.scanned_at).toLocaleDateString() : '',
                    barcodeRef: React.createRef(),
                    additionalInfoRef: React.createRef(),
                    success: true
                })
            }

            for (let i = 1; i < 150; i++) {
                newRows.push({
                    lab_number: lab_number_result.data.highest_lab_number + i,
                    barcode: '',
                    status: null,
                    additional_info: '',
                    barcodeRef: React.createRef(),
                    additionalInfoRef: React.createRef(),
                })
            }

            setRows(newRows)
        } catch (err) {
            NotificationManager.error("Couldn't load from requested kit.")
        }
        setLoading(false)
    }


    return <Contents>
        <FlexRow style={{ marginBottom: 30 }}>
            <TopTitle>Scan</TopTitle>
        </FlexRow>
        
        <Row style={{ marginBottom: 30 }}>
            <LoadFrom reload={loadRowsFromNumber} />
            {!['TECHNICIAN'].includes(claims?.role) && <DownloadScanReport />}
        </Row>
        
        <ScanTable 
            rows={rows} 
            setRows={setRows} 
            loading={loading}
            setLoading={setLoading}
            scanActive={scanOption === 'Scan Entry'}
        />
        
        
    </Contents>

}
